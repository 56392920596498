import React from "react";
import Header from "./Header";
import { Outlet } from "react-router-dom";
import "../index.css";

function Layout() {
  return (
    <div>
      {/* <Header /> */}

      {/* <main className="flex-grow mb-12 lg:mb-0 mt-12 lg:mt-24 overflow-auto"> */}
      <main>
        <Outlet />
      </main>

      {/* <footer>
        <div className="px-6 py-6">
          <p>&copy; CARDEX. All rights reserved.</p>
        </div>
      </footer> */}
    </div>
  );
}

export default Layout;
